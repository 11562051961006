import React from 'react';
import '../../assets/SystemOption.css'
import { axiosSign } from '../../api/axiosSign'
import store from '../../reducer'
import axios from 'axios'
import { Button, Input, Space, DatePicker, Select, message } from 'antd';
import { changeMenu, changeBill } from '../../reducer/actionCreators'

const { TextArea } = Input;
const dateFormat = 'YYYY/MM/DD';

class SystemOption extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      user: '',//発行者アカウント
      name:'',//発行者名前
      manager: '',//ご担当者様
      billType: '',//請求書発行会社
      postcode1: '',//郵便番号前三位
      postcode2: '',//郵便番号后四位
      address: '',//住所
      company: '',//会社名
      startDate: '',//請求日
      endDate: '',//お支払期限
      remark: '',//備考

      price0: 0,//非課税対象金額
      tax0: '-',//非課税消費税
      price8: 0,//8%对象金额
      tax8: 0,//8%消费税
      price10: 0,//10%对象金额
      tax10: 0,//10%消费税
      totalPrice: 0,//总对象金额
      totalTax: 0,//总消费税
      price: 0,//总金额（小计+消费税）

      content: [
        { date: '', name: '', quantity: '', price: '', total_price: '', applicable: '' }
      ],//内訳

      billTypeValue: [],//可选請求書発行会社
      centent_num:1,//内訳数量
    }
    store.subscribe(this.storeChange) //订阅Redux的状态
  }
  storeChange = () => {
    this.setState(store.getState())
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return
    }
  }//在组件销毁的时候将异步方法撤销

  //渲染后自动执行
  UNSAFE_componentWillMount() {
    //更新menu
    store.dispatch(changeMenu(['3']))
    //复制请求书数据
    var copy_data = store.getState().billValue

    console.log(copy_data)

    if(copy_data !== 'null'){

      copy_data.content.map((val,index)=>{
        delete val.id
        val.date = ''
      })

      setTimeout(()=>{
        this.setState({
          name: copy_data.name.substring(3),//発行者名前
          manager: copy_data.manager.substring(0,copy_data.manager.length-1),//ご担当者様
          postcode1: copy_data.postcode1,//郵便番号前三位
          postcode2: copy_data.postcode2,//郵便番号后四位
          address: copy_data.address,//住所
          company: copy_data.company,//会社名
          remark: copy_data.remark,//備考
          content:copy_data.content,
        })
  
        var centent_num_copy = 0
        copy_data.content.map((val,index)=>{
          centent_num_copy += 1
        })
  
        setTimeout(()=>{
          this.setState({
            centent_num : centent_num_copy
          })
          console.log('内訳数量：'+this.state.centent_num)
  
          //自动求和
          this.getTotal()
        },300)
      },300)
    }

    //根据所属公司更新可发行请求书
    var select_jtmh = {
      value: 'jtmh',
      label: 'JTM請求書',
    }
    var select_tensai = {
      value: 'tensai',
      label: 'JTM天彩請求書',
    }
    var select_kamome = {
      value: 'kamome',
      label: 'カモメ請求書',
    }
    var select_dreamjp = {
      value: 'dreamjp',
      label: 'ドリーム・ジャパン請求書',
    }
    var select_ones = {
      value: 'ones',
      label: 'ワンズライフジャパン請求書',
    }
    var select_hanamitsu = {
      value: 'hanamitsu',
      label: '華光観光請求書',
    }
    var select_haya = {
      value: 'haya',
      label: 'HAYA請求書',
    }

    //获取权限
    axiosSign('getRole', '')
      .then((res) => {
        console.log(res.data)
        let billTypeValueCopy = []
        //根据所属公司更新可发行请求书的值
        if (res.data.indexOf('ROLE_ADMIN') > -1) {
          billTypeValueCopy.push(select_jtmh)
          billTypeValueCopy.push(select_tensai)
          billTypeValueCopy.push(select_kamome)
          billTypeValueCopy.push(select_dreamjp)
          billTypeValueCopy.push(select_ones)
          billTypeValueCopy.push(select_hanamitsu)
          billTypeValueCopy.push(select_haya)
        }
        if (res.data.indexOf('ROLE_JTMH') > -1) {
          billTypeValueCopy.push(select_jtmh)
        }
        if (res.data.indexOf('ROLE_TENSAI') > -1) {
          billTypeValueCopy.push(select_tensai)
        }
        if (res.data.indexOf('ROLE_KMM') > -1) {
          billTypeValueCopy.push(select_kamome)
        }
        if (res.data.indexOf('ROLE_HUA') > -1) {
          billTypeValueCopy.push(select_hanamitsu)
        }
        if (res.data.indexOf('ROLE_DJ') > -1) {
          billTypeValueCopy.push(select_dreamjp)
        }
        if (res.data.indexOf('ROLE_HAYA') > -1) {
          billTypeValueCopy.push(select_haya)
        }
        if (res.data.indexOf('ROLE_ONES') > -1) {
          billTypeValueCopy.push(select_ones)
        }
        this.setState({
          billTypeValue: billTypeValueCopy
        })
      })
      .catch((error) => { console.log('axios 获取数据失败' + error) })

    //获取用户名
    axiosSign('getName', '')
      .then((res) => {
        console.log(res.data)
        this.setState({
          user: res.data
        })
      })
      .catch((error) => { console.log('axios 获取数据失败' + error) })
  }

  //生成并下载请求书
  onFinish = () => {

    //验证数据
    if (this.state.startDate === '') {
      alert('請求日を入力してください')
    } else if (this.state.endDate === '') {
      alert('お支払期限を入力してください')
    } else if (this.state.billType === '') {
      alert('請求書発行会社を入力してください')
    } else if (this.state.price0 === '') {
      alert('非課税对象金额を入力してください')
    } else if (this.state.price8 === '') {
      alert('8%对象金额を入力してください')
    } else if (this.state.name === '') {
      alert('担当者の名前を入力してください')
    } else if (this.state.price10 === '') {
      alert('10%对象金额を入力してください')
    }else if (this.state.totalPrice === '') {
      alert('总对象金额を入力してください')
    } else if (this.state.content[0].date === '' || this.state.content[0].name === '' || this.state.content[0].quantity === '' || this.state.content[0].price === '' || this.state.content[0].total_price === '') {
      alert('内訳を入力してください')
    } else {
      this.setState({
        loading: true
      })
      setTimeout(() => {
        //担当者数据处理
        let managerCopy = this.state.manager
        if (managerCopy === '') {
          managerCopy = 'ご担当者様'
        } else {
          managerCopy = managerCopy + '様'
        }
        let data = {
          user: this.state.user,//発行者
          name:'担当:' + this.state.name,//発行者名前
          manager: managerCopy,//ご担当者様
          billType: this.state.billType,//請求書発行会社
          postcode1: this.state.postcode1,//郵便番号前三位
          postcode2: this.state.postcode2,//郵便番号后四位
          address: this.state.address,//住所
          company: this.state.company,//会社名
          startDate: this.state.startDate,//請求日
          endDate: this.state.endDate,//お支払期限
          remark: this.state.remark,//備考
          content: this.state.content,//内訳
          price0: this.state.price0,//非課税対象金額
          tax0: this.state.tax0,//非課税消費税
          price8: this.state.price8,//8%对象金额
          tax8: this.state.tax8,//8%消费税
          price10: this.state.price10,//10%对象金额
          tax10: this.state.tax10,//10%消费税
          totalPrice: this.state.totalPrice,//总对象金额
          totalTax: this.state.totalTax,//总消费税
          price: this.state.price,//总金额（小计+消费税）

          if_delete: 0,//是否删除
        }

        axios.post('https://invoice-api.kamome-travel.info/bill', data, {
          // 设置responseType对象格式为blob
          responseType: "blob",
        }).then(res => {
          // 创建下载的链接
          const url = window.URL.createObjectURL(new Blob([res.data],
            // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式                          
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
          const link = document.createElement('a');
          link.href = url;
          // 从header中获取服务端命名的文件名
          const fileName = decodeURI(res.headers['請求書']);
          link.setAttribute('請求書', fileName);
          document.body.appendChild(link);
          link.click();
          message.success('発行成功')

          store.dispatch(changeBill('null'))
        })
          .catch((error) => {
            message.warning('エラー')
          })

        console.log(data)
        this.setState({
          loading: false
        })
      }, 1000)
    }

  }

  //更改請求日
  changeStartDate = (date, dateString) => {
    if (dateString) {
      console.log(dateString);
      this.setState({
        startDate: dateString
      })
    } else {
      console.log('无')
      this.setState({
        startDate: '无'
      })
    }
  };

  //更改お支払期限
  changeEndDate = (date, dateString) => {
    if (date) {
      console.log(dateString);
      this.setState({
        endDate: dateString
      })
    } else {
      console.log('无')
      this.setState({
        endDate: '无'
      })
    }
  };

  //切换請求書発行会社
  handleChange = (value) => {
    console.log(value);
    this.setState({
      billType: value
    })
  };

  //绑定input通用方法
  changeData = (data, e) => {
    console.log(data + '=' + e.target.value)
    this.setState({
      [data]: e.target.value
    })
  }

  //增加一条content
  addContent = () => {
    if(this.state.centent_num < 15){
      let copyContent = this.state.content
      let copyCentent_num = this.state.centent_num + 1
      let add = { date: '', name: '', quantity: '', price: '', total_price: '', applicable: '' }
      copyContent.push(add)
      this.setState({
        content: copyContent,
        centent_num:copyCentent_num
      })
    }else{
      message.warning('内訳数は15まで')
    }
  }

  //删除一条content
  delContent = (e) => {
    let copyContent = this.state.content
    let copyCentent_num = this.state.centent_num - 1
    copyContent.splice(e.target.name, 1)
    this.setState({
      content: copyContent,
      centent_num:copyCentent_num
    })

    //自动求和
    this.getTotal()
  }

  //绑定content日付
  changeContentDate = (date, dateString, name, date0) => {
    let dateValue = dateString
    let key = name
    console.log(dateValue + '---' + key)
    let copyContent = this.state.content
    copyContent[key].date = dateValue
    this.setState({
      content: copyContent
    })
  };

  //绑定content品名
  changeContentName = (e) => {
    let name = e.target.value
    let key = e.target.name
    console.log(name + '---' + key)
    let copyContent = this.state.content
    copyContent[key].name = name
    this.setState({
      content: copyContent
    })
  }

  //绑定content数量
  changeContentQuantity = (e) => {
    let quantity = e.target.value
    let key = e.target.name
    console.log(quantity + '---' + key)
    let copyContent = this.state.content
    copyContent[key].quantity = quantity
    //自动求积
    copyContent[key].total_price = copyContent[key].quantity * copyContent[key].price
    this.setState({
      content: copyContent
    })

    //自动求和
    this.getTotal()
  }

  //绑定content単価
  changeContentPrice = (e) => {
    let price = e.target.value
    let key = e.target.name
    console.log(price + '---' + key)
    let copyContent = this.state.content
    copyContent[key].price = price
    //自动求积
    copyContent[key].total_price = copyContent[key].quantity * copyContent[key].price
    this.setState({
      content: copyContent
    })

    //自动求和
    this.getTotal()
  }

  //绑定content金額
  changeContentTotalPrice = (e) => {
    let total_price = e.target.value
    let key = e.target.name
    console.log(total_price + '---' + key)
    let copyContent = this.state.content
    copyContent[key].total_price = total_price
    this.setState({
      content: copyContent
    })

    //自动求和
    this.getTotal()
  }

  //绑定applicable適用
  changeContentApplicable = (value, option) => {
    let applicable = value
    let key = option.name.index
    console.log(applicable + '---' + key)
    let copyContent = this.state.content
    copyContent[key].applicable = applicable

    this.setState({
      content: copyContent,
    })

    //自动求和
    this.getTotal()
  }

  //自动计算
  getTotal = () => {
    let copyPrice0 = 0;
    let copyPrice8 = 0;
    let copyPrice10 = 0;
    let copyContent = this.state.content
    copyContent.map((val, index) => {
      if (val.applicable === '非課税') {
        copyPrice0 += Number(val.total_price)
      }
      if (val.applicable === '8%') {
        copyPrice8 += Number(val.total_price)
      }
      if (val.applicable === '10%') {
        copyPrice10 += Number(val.total_price)
      }
      return ''
    })

    this.setState({
      price0: Number(copyPrice0),//非課税対象金額
      price8: Math.round(Number(copyPrice8) / 1.08),//8%对象金额
      price10: Math.round(Number(copyPrice10) / 1.1),//10%对象金额
      tax8: Number(copyPrice8) - Math.round(Number(copyPrice8) / 1.08),//8%消费税
      tax10: Number(copyPrice10) - Math.round(Number(copyPrice10) / 1.1),//10%消费税
      totalPrice: Number(copyPrice0) + Math.round(Number(copyPrice8) / 1.08) + Math.round(Number(copyPrice10) / 1.1),//总对象金额
      totalTax: Number(copyPrice8) - Math.round(Number(copyPrice8) / 1.08) + Number(copyPrice10) - Math.round(Number(copyPrice10) / 1.1),//总消费税
      price: Number(copyPrice0) + Number(copyPrice8) + Number(copyPrice10),//总金额（小计+消费税）
    })
  }

  render() {
    return (
      <div className='systemoption'>
        <div className='systemoption-title'>
          新規発行
        </div>
        <div className='systemoption-box'>
          <div className='systemoption-content'>

            <div className='systemoption-content-top'>
              <div className='systemoption-content-title'>
                請求先<br />
                <Space.Compact style={{ 'width': '100%' }}>
                  <Input value={this.state.postcode1} addonBefore="〒" onChange={(e) => this.changeData('postcode1', e)} className='systemoption-input1' placeholder="150" maxLength={3}></Input>
                  <Input value={this.state.postcode2} addonBefore="-" onChange={(e) => this.changeData('postcode2', e)} className='systemoption-input1' placeholder="0001" maxLength={4}></Input>
                </Space.Compact>
                <Input value={this.state.address} addonBefore="住所（市/区）" onChange={(e) => this.changeData('address', e)} className='systemoption-input1' placeholder="東京都新宿区" maxLength={38}></Input>
                <Input value={this.state.company} addonBefore="会社名" onChange={(e) => this.changeData('company', e)} className='systemoption-input1' placeholder="株式会社ABC" maxLength={28}></Input>
                <Input value={this.state.manager} addonBefore="ご担当者" addonAfter="様" onChange={(e) => this.changeData('manager', e)} className='systemoption-input1' placeholder="山本" maxLength={18}></Input>
              </div>
              <div className='systemoption-content-title2'>
                <div className='systemoption-content-title2-bill'>
                  請求書
                </div>
                <Select
                  className='systemoption-input1'
                  onChange={this.handleChange}
                  options={this.state.billTypeValue}
                  placeholder='発行会社を選択して'
                />
                <Input addonBefore="合計金額" className='systemoption-input1' disabled value={this.state.price}></Input>
                <DatePicker className='systemoption-input1' placeholder='請求日' onChange={this.changeStartDate} format={dateFormat} />
                <DatePicker className='systemoption-input1' placeholder='お支払期限' onChange={this.changeEndDate} format={dateFormat} />
                <Input addonBefore="担当" className='systemoption-input1' onChange={(e) => this.changeData('name', e)} value={this.state.name} maxLength={12}></Input>
              </div>
            </div>

            <div style={{ 'clear': 'both' }}></div>

            <div className='systemoption-content-title3' style={{ 'marginBottom': '10px' }}>
              請求内訳
            </div>

            <div className='systemoption-content-menus'>
              <div className='systemoption-content-menu'>
                -日付-
              </div>
              <div className='systemoption-content-menu'>
                -品名-
              </div>
              <div className='systemoption-content-menu'>
                -数量-
              </div>
              <div className='systemoption-content-menu'>
                -単価-
              </div>
              <div className='systemoption-content-menu'>
                -金額-
              </div>
              <div className='systemoption-content-menu'>
                -適用-
              </div>
            </div>

            <div style={{ 'clear': 'both' }}></div>

            {
              this.state.content.map((val, index) => {
                return <div key={index} className='systemoption-content-contents'>
                  <DatePicker name={index} className='systemoption-content-content' placeholder='2023/9/16' onChange={(date, dateString,event)=>this.changeContentDate(date, dateString,index,val.date)} format={dateFormat} />
                  <Input name={index} value={val.name} className='systemoption-content-content' placeholder="バス代" maxLength={30} onChange={this.changeContentName} />
                  <Input name={index} value={val.quantity} className='systemoption-content-content' placeholder="2" maxLength={7} onChange={this.changeContentQuantity} />
                  <Input name={index} value={val.price} className='systemoption-content-content' placeholder="3000" maxLength={10} onChange={this.changeContentPrice} />
                  <Input name={index} value={val.total_price} className='systemoption-content-content' placeholder="6000" maxLength={17} onChange={this.changeContentTotalPrice} />
                  <Select
                    onSelect={this.changeContentApplicable}
                    name={index}
                    className='systemoption-content-content'
                    allowClear
                    value={val.applicable}
                    placeholder="10%"
                    options={[
                      {
                        value: '非課税',
                        name: { index },
                      },
                      {
                        value: '8%',
                        name: { index },
                      },
                      {
                        value: '10%',
                        name: { index },
                      },
                    ]}
                  />
                  <Button name={index} onClick={(e) => this.delContent(e)}><div className='systemoption-content-content-button-s'>-</div></Button>
                </div>
              })
            }

            <Button className='systemoption-content-content-button' type="primary" onClick={this.addContent}>内訳追加</Button>

            <div style={{ 'clear': 'both' }}></div>

            <div className='systemoption-content-item'>
              <div className='systemoption-content-title3'>
                備考
              </div>

              <div className='systemoption-content-bottm'>
                <div className='systemoption-content-bottm-left'>
                  <TextArea
                    showCount
                    maxLength={140}
                    style={{
                      height: 148,
                      resize: 'none',
                    }}
                    onChange={(e) => this.changeData('remark', e)}
                    placeholder="備考"
                    value={this.state.remark}
                  />
                </div>
                <div className='systemoption-content-bottm-middle'>
                  <Input addonBefore="非課税" className='systemoption-input1' disabled value={this.state.price0} onChange={(e) => this.changeData('price0', e)} maxLength={9} placeholder="1000"></Input>
                  <Input addonBefore="8%対象" className='systemoption-input1' disabled value={this.state.price8} onChange={(e) => this.changeData('price8', e)} maxLength={9} placeholder="1000"></Input>
                  <Input addonBefore="10%対象" className='systemoption-input1' disabled value={this.state.price10} onChange={(e) => this.changeData('price10', e)} maxLength={9} placeholder="1000"></Input>
                  <Input addonBefore="合計" className='systemoption-input1' disabled value={this.state.totalPrice} onChange={(e) => this.changeData('totalPrice', e)} maxLength={9} placeholder="3000"></Input>
                </div>
                <div className='systemoption-content-bottm-right'>
                  <Input addonBefore="消費税" className='systemoption-input1' disabled value={this.state.tax0} maxLength={9} placeholder="-" ></Input>
                  <Input addonBefore="消費税" className='systemoption-input1' disabled value={this.state.tax8} maxLength={9} placeholder="80" ></Input>
                  <Input addonBefore="消費税" className='systemoption-input1' disabled value={this.state.tax10} maxLength={9} placeholder="100"></Input>
                  <Input addonBefore="消費税" className='systemoption-input1' disabled value={this.state.totalTax} maxLength={9} placeholder="180"></Input>
                </div>

                <div style={{ 'clear': 'both' }}></div>
              </div>


              <Button type="primary" loading={this.state.loading} className='systemoption-create' onClick={this.onFinish}>
                発行
              </Button>


            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default SystemOption;
